import { DateRange, Session, SessionFilter } from '@session/graphql';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SEARCH_SESSIONS } from '../../graphql/news/queries';
import { addLoading, removeLoading } from '../../redux/utils/actions';
import { getDateHourAsString } from '../../utils/dateUtils';
import { firstLowerCaseGqlDefinition } from '../../utils/stringUtils';
import { Text } from '../Text';
import { useLazyQueryHook } from '../hooks/useLazyQueryHook';
import { appsSelector } from '../../redux/apps/selectors';
import { Input } from '../Input';
import { debounce } from 'lodash';
import { DateFilter } from '../DateFilter';

export const Monitor: FC = () => {
  const [searchSessions, { data, loading, startPolling, stopPolling }] =
    useLazyQueryHook({ queryGql: SEARCH_SESSIONS });
  const { currentApp } = useSelector(appsSelector);

  useEffect(() => {
    if (currentApp) startPolling(10000);

    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling, currentApp]);

  const [filter, setFilter] = useState<SessionFilter>({});
  const [hasMore, setHasMore] = useState(false);

  const perPage = 5;
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (filter.client) {
      searchSessions({
        variables: {
          filter,
          pagination: {
            offset: (page - 1) * perPage,
            limit: perPage,
          },
        },
        fetchPolicy: 'network-only',
      });
    }
  }, [searchSessions, filter, page]);

  useEffect(() => {
    if (currentApp) {
      setFilter((filter) => ({ ...filter, client: currentApp }));
    }
  }, [currentApp]);

  const [monitorData, setMonitorData] = useState<Session[]>([]);

  useEffect(() => {
    if (data) {
      let tmp = data[firstLowerCaseGqlDefinition(SEARCH_SESSIONS)];
      setMonitorData(tmp);
      setHasMore(tmp.length > 0 && tmp.length % perPage === 0);
    }
  }, [data]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (loading) {
      dispatch(addLoading());
    } else {
      dispatch(removeLoading());
    }

    return () => {
      dispatch(removeLoading());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const [username, setUsername] = useState('');

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);

  useEffect(() => {
    setFilter((filter) => ({ ...filter, username }));
  }, [username]);

  let today = new Date();
  today.setHours(0, 0, 0, 0);
  let tonight = new Date();
  tonight.setHours(23, 59, 0, 0);
  let lastWeek = new Date(today);
  lastWeek.setDate(today.getDate() - 7);
  let lastMonth = new Date(today);
  lastMonth.setDate(1);
  let lastYear = new Date(lastMonth);
  lastYear.setMonth(0);
  let fullYear = new Date();
  fullYear.setFullYear(today.getFullYear() - 1);
  const valuesDateFilter: any = {
    '0': {
      from: today.getTime(),
      to: tonight.getTime(),
    },
    '1': {
      from: lastWeek.getTime(),
      to: tonight.getTime(),
    },
    '2': {
      from: lastMonth.getTime(),
      to: tonight.getTime(),
    },
    '3': {
      from: lastYear.getTime(),
      to: tonight.getTime(),
    },
  };

  const [dateRange, setDateRange] = useState<DateRange>(valuesDateFilter[0]);

  useEffect(() => {
    setFilter((filter) => ({ ...filter, dateRange }));
  }, [dateRange]);

  const onChangeDateFilter = (value: any) => {
    setDateRange(value);
  };

  const [onlyActive, setOnlyActive] = useState(false);

  useEffect(() => {
    setFilter((filter) => ({ ...filter, onlyActive }));
  }, [onlyActive]);

  return (
    <>
      <div style={{ backgroundColor: '#FAFAFA' }} className="h-full">
        <div className="flex flex-col h-screen w-full items-center justify-around">
          <div
            className="h-12 w-full pl-3 py-2 mt-1 flex items-center justify-between bg-white"
            style={{ boxShadow: '2px 0px 8px rgba(0, 0, 0, 0.1)' }}
          >
            <div className="flex items-center justify-between ml-3 mr-72 w-full">
              <Text text={'Filtri'} className="font-semibold" />
              <div className="flex flex-row items-center space-x-2">
                <span className="font-semibold">
                  <Text text={'Username'} className="font-semibold" />
                  :&nbsp;
                </span>
                <Input
                  placeholder="Username"
                  value={username}
                  onChange={debouncedChangeHandler}
                />
              </div>
              <div className="flex flex-row items-center space-x-2">
                <span className="font-semibold">
                  <Text text={'Data'} className="font-semibold" />
                  :&nbsp;
                </span>
                <DateFilter
                  values={valuesDateFilter}
                  onChange={onChangeDateFilter}
                />
              </div>
              <div className="flex flex-row items-center space-x-2">
                <Text text={'Solo attivi'} className="font-semibold" />
                &nbsp;
                <input
                  type="checkbox"
                  checked={onlyActive}
                  onChange={(e) => setOnlyActive(e.target.checked)}
                />
              </div>
            </div>
          </div>
          <div className="flex w-full pt-2 px-56 mt-0.5 mb-4">
            <div
              className="flex w-full py-4 px-10 flex-row max-w-full rounded justify-between m-1 items-center"
              style={{
                color: '#9C9D9D',
              }}
            >
              <table width="100%">
                <tbody>
                  <tr>
                    <td width="30%" align="center">
                      <Text text={'Username'} />
                    </td>
                    <td width="30%" align="center">
                      <Text text={'Inizio sessione'} />
                    </td>
                    <td width="30%" align="center">
                      <Text text={'Fine sessione'} />
                    </td>
                    <td width="10%" align="center">
                      <Text text={'Stato'} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-full px-48">
            <div
              className="w-full pb-1"
              style={{
                borderTop: '1px solid #DCDCDC',
              }}
            >
              &nbsp;
            </div>
          </div>
          <div className="flex h-full w-full py-1 px-56 rounded-xl flex-col scrollbar overflow-y-auto mt-0.5 mb-4">
            {monitorData.map((session, i) => (
              <div
                className="flex py-4 px-10 flex-row max-w-full rounded justify-between m-1 bg-white items-center"
                style={{
                  border: '1px solid white',
                  color: '#9C9D9D',
                }}
                key={session.id}
              >
                <table width="100%">
                  <tbody>
                    <tr>
                      <td width="30%" align="center">
                        <Text
                          text={session.username}
                          skipTranslation={true}
                          style={{
                            fontSize: '1.125rem',
                            lineHeight: '1.75rem',
                          }}
                          className="italic"
                        />
                      </td>
                      <td width="30%" align="center">
                        <Text
                          text={getDateHourAsString(
                            session.sessionStart as any
                          )}
                          skipTranslation={true}
                          style={{
                            fontSize: '0.875rem',
                            lineHeight: '1.25rem',
                          }}
                        />
                      </td>
                      <td width="30%" align="center">
                        <Text
                          text={
                            session.sessionEnd
                              ? getDateHourAsString(session.sessionEnd as any)
                              : '-'
                          }
                          skipTranslation={true}
                          style={{
                            fontSize: '0.875rem',
                            lineHeight: '1.25rem',
                          }}
                        />
                      </td>
                      <td width="10%" align="center">
                        <Text
                          text={session.active ? 'Online' : 'Offline'}
                          skipTranslation={true}
                          style={{
                            fontSize: '0.875rem',
                            lineHeight: '1.25rem',
                            color: session.active ? '#00BFA6' : '#FF0000',
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ))}
            <div className="w-full px-48">
              <div
                className="w-full pb-1"
                style={{
                  borderBottom: '1px solid #DCDCDC',
                }}
              >
                &nbsp;
              </div>
            </div>
            <div
              className="flex w-full py-4 px-10 flex-row max-w-full rounded justify-around m-1 items-center"
              style={{
                color: '#9C9D9D',
              }}
            >
              <div className="w-1/12 flex flex-row space-x-2">
                <div
                  className=""
                  style={{
                    color: page > 1 ? '#9C9D9D' : '#DCDCDC',
                    cursor: page > 1 ? 'pointer' : 'not-allowed',
                  }}
                  onClick={() => (page > 1 ? setPage(page - 1) : null)}
                >
                  &lt;
                </div>
                <div className="">{page}</div>
                <div
                  className=""
                  style={{
                    color: hasMore ? '#9C9D9D' : '#DCDCDC',
                    cursor: hasMore ? 'pointer' : 'not-allowed',
                  }}
                  onClick={() => (hasMore ? setPage(page + 1) : null)}
                >
                  &gt;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
