import { FC } from 'react';
import { Link } from 'react-router-dom';
import { SvgIcon } from '../SvgIcon';
import { AevolutaLogo } from '../../svg';

export const Logo: FC = () => {
  return (
    <Link
      to={'/sessionmonitor'}
      style={{
        textDecoration: 'none',
        color: 'inherit',
      }}
    >
      <div className="flex flex-row">
        <SvgIcon svg={<AevolutaLogo />} className="w-auto max-w-full h-8" />
      </div>
    </Link>
  );
};
