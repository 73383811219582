import { gql } from '@apollo/client/core';

export const LIST_CLIENTS = gql`
  query ListClients {
    listClients
  }
`;

export const SEARCH_SESSIONS = gql`
  query SearchSessions($filter: SessionFilter, $pagination: Pagination) {
    searchSessions(filter: $filter, pagination: $pagination) {
      id
      active
      username
      sessionStart
      sessionEnd
    }
  }
`;
