import { FC } from 'react';
import { KeycloakService } from '../../services/KeycloakService';
import { Logout } from '../../svg';
import { SvgIcon } from '../SvgIcon';

export const LogoutIcon: FC = () => {
  return (
    <SvgIcon
      svg={<Logout className=" w-6 h-5" />}
      pointer={true}
      onClick={() => KeycloakService.doLogout()}
    />
  );
};
