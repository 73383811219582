import { useTranslation } from 'react-i18next';
import {
  FunctionComponent,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react';
import { SvgIcon } from './SvgIcon';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  skipTranslation?: boolean;
  Icon?: any;
  isDisabled?: boolean;
  onClick?: (...args: any[]) => void;
}

export const Input: FunctionComponent<InputProps> = ({
  className,
  skipTranslation,
  placeholder,
  value,
  children,
  required,
  onBlur,
  Icon,
  isDisabled,
  onClick,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();
  const [requiredBlur, setRequiredBlur] = useState('');
  const [currentValue, setCurrentValue] = useState(value);
  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  if (!props.style) props.style = {};
  if (!props.style.lineHeight) props.style.lineHeight = '1.5rem';

  return (
    <span
      className={`flex flex-row justify-between items-center p-2 mt-1 flex-grow focus:outline-none shadow rounded ring-1 focus:ring-gray-400 ${
        value ? 'ring-gray-300' : 'ring-blue-300'
      } ${className || ''} ${requiredBlur}`}
      style={{ height: '2.25rem' }}
    >
      <input
        type="text"
        className="flex-grow focus:outline-none outline-none h-full w-full"
        placeholder={
          placeholder ? (skipTranslation ? placeholder : t(placeholder)) : ''
        }
        value={currentValue}
        required={required}
        onBlur={(e) => {
          if (required) {
            setRequiredBlur(value ? '' : 'ring-red-500');
          }
          if (onBlur) onBlur(e);
        }}
        onChange={(e) => {
          onChange && onChange(e);
          setCurrentValue(e.target.value);
        }}
        {...props}
        style={props.style}
      >
        {children}
      </input>

      {Icon && (
        <SvgIcon
          svg={Icon}
          style={{
            fill: isDisabled ? '#ccc' : 'red',
          }}
          onClick={
            isDisabled ? () => {} : () => onClick && onClick(currentValue)
          }
          pointer={!isDisabled}
        />
      )}
    </span>
  );
};
