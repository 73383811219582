import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ErrorPage } from './components/ErrorPage';
import styled from 'styled-components';
import { Header } from './components/Header';
import '@fontsource/poppins';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';
import '@fontsource/poppins/900.css';
import { Notifications } from './components/Notifications/Notifications';
import { Loading } from './components/Loading';
import { Monitor } from './components/Monitor';

export const App: FC = () => {
  return (
    <Container>
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <Navigate
              to={{
                pathname: '/sessionmonitor',
              }}
            />
          }
        />
        <Route
          path="/*"
          element={
            <Navigate
              to={{
                pathname: '/sessionmonitor',
              }}
            />
          }
        />
        <Route path="/sessionmonitor/" element={<Monitor />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Notifications />
      <Loading />
    </Container>
  );
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;
