import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appsSelector } from '../../redux/apps/selectors';
import { setApps, setCurrentApp } from '../../redux/apps/actions';
import { GenericSelect } from '../GenericSelect';
import { useQueryHook } from '../hooks/useQueryHook';
import { LIST_CLIENTS } from '../../graphql/news/queries';
import { firstLowerCaseGqlDefinition } from '../../utils/stringUtils';
import { addLoading, removeLoading } from '../../redux/utils/actions';

export const AppSelector: FC = () => {
  const dispatch = useDispatch();
  const { apps, currentApp } = useSelector(appsSelector);

  const [appsMapped, setAppsMapped] = useState<any>([]);
  const [app, setApp] = useState<any>({ label: '', value: '' });

  const { data, loading } = useQueryHook({ queryGql: LIST_CLIENTS });
  useEffect(() => {
    if (loading) {
      dispatch(addLoading());
    } else {
      dispatch(removeLoading());
    }

    return () => {
      dispatch(removeLoading());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);
  useEffect(() => {
    if (data && data[firstLowerCaseGqlDefinition(LIST_CLIENTS)]) {
      dispatch(setApps(data[firstLowerCaseGqlDefinition(LIST_CLIENTS)]));
    }
  }, [data, dispatch]);

  useEffect(() => {
    setAppsMapped(apps.map((app) => ({ label: app, value: app })));
    if (apps.length > 0) {
      dispatch(setCurrentApp(apps[0]));
    }
  }, [apps, dispatch]);

  useEffect(() => {
    setApp({ label: currentApp, value: currentApp });
  }, [currentApp, dispatch]);

  return (
    <>
      <span className="text-gray-400">App: </span>
      <GenericSelect
        id="App"
        onChange={(newValue) => dispatch(setCurrentApp(newValue!.value))}
        options={appsMapped}
        showSelectionCircle={false}
        showSelectedOption={true}
        showSelectedOptionLabel={true}
        disableBorder={true}
        value={app}
      />
    </>
  );
};
