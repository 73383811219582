import Keycloak from 'keycloak-js';

// eslint-disable-next-line no-restricted-globals
let host = location.host;
let file = '/keycloak.json';
if (host.indexOf('localhost') !== -1 || host.indexOf('demo') !== -1) {
  file = '/keycloak-demo.json';
}

const kc = new Keycloak(file);

const initKeycloak = (onAuthenticatedCallback: any) => {
  kc.init({
    onLoad: 'login-required',
    pkceMethod: 'S256',
  }).then((authenticated: boolean) => {
    if (authenticated) {
      if (
        kc.tokenParsed?.realm_access?.roles.some(
          (role: string) => role === 'session-access'
        )
      ) {
        onAuthenticatedCallback();
        watchRefresh();
        watchSessionLifecycle();
      } else {
        // force a logout if the user does not have the session-access role
        doLogout();
      }
    } else {
      doLogin();
    }
  });
};

const doLogin = kc.login;

const doLogout = kc.logout;

const getToken = () => kc.token;

const isLoggedIn = () =>
  !!kc.token &&
  kc.tokenParsed?.realm_access?.roles.some(
    (role: string) => role === 'session-access'
  );

const updateToken = (successCallback: any) =>
  kc
    .updateToken(60)
    .then(function (refreshed) {
      if (refreshed) {
        console.log('Token was successfully refreshed');
      } else {
        console.log('Token is still valid');
      }
      successCallback();
    })
    .catch(function () {
      console.log('Failed to refresh the token, or the session has expired');
      doLogin();
    });

const getUsername = () => kc.tokenParsed?.preferred_username;

const getFullName = () =>
  `${kc.tokenParsed?.given_name} ${kc.tokenParsed?.family_name}`;

const getTokenExpiration: any = () => kc.tokenParsed?.exp;

const getLocale = () => kc.tokenParsed?.locale || 'en-EN';

const hasRole = (roles: string[]) =>
  roles.some((role) => kc.hasRealmRole(role));

const isLoggedInWithRoles = (roles?: string[]) =>
  isLoggedIn() && (!roles || roles.length === 0 || hasRole(roles!));

const watchRefresh = () => {
  setTimeout(() => {
    updateToken(watchRefresh);
  }, (getTokenExpiration() - Date.now() / 1000) * 1000 - 10000); // 10s error window
};

const watchSessionLifecycle = () => {
  // doLogout after 8 hours
  setTimeout(() => {
    doLogout();
  }, 8 * 60 * 60 * 1000);
};

export const KeycloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getTokenExpiration,
  updateToken,
  getFullName,
  getUsername,
  getLocale,
  hasRole,
  isLoggedInWithRoles,
};
