import { KeycloakService } from '../services/KeycloakService';

export const getDateHourAsString = (epochMillis: number) => {
  const locale =
    KeycloakService.getLocale() === 'en' ||
    KeycloakService.getLocale() === 'en-EN'
      ? 'en-GB'
      : KeycloakService.getLocale();
  const date = new Date(epochMillis);
  let hours = date.getHours().toString();
  if (hours.length === 1) hours = '0' + hours;
  let minutes = date.getMinutes().toString();
  if (minutes.length === 1) minutes = '0' + minutes;
  return `${date.toLocaleDateString(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })} ${hours}:${minutes}`;
};

export const getDateAsString = (epochMillis: number) => {
  const locale =
    KeycloakService.getLocale() === 'en' ||
    KeycloakService.getLocale() === 'en-EN'
      ? 'en-GB'
      : KeycloakService.getLocale();
  return new Date(epochMillis).toLocaleDateString(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export const getHoursAndMinutesAsString = (epochMillis: number) => {
  const date = new Date(epochMillis);
  let hours = date.getHours().toString();
  if (hours.length === 1) hours = '0' + hours;
  let minutes = date.getMinutes().toString();
  if (minutes.length === 1) minutes = '0' + minutes;
  return `${hours}:${minutes}`;
};

export const isToday = (epocMillis: number) => {
  return getDateAsString(Date.now()) === getDateAsString(epocMillis);
};

export const isYesterday = (epocMillis: number) => {
  let yesterday = new Date();
  yesterday.setHours(0, 0, 0, 0);
  yesterday.setDate(yesterday.getDate() - 1);
  return getDateAsString(yesterday.getTime()) === getDateAsString(epocMillis);
};
