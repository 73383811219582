import { FC, useState } from 'react';
import { GenericSelect } from '../GenericSelect';
import { getDateAsString } from '../../utils/dateUtils';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import Modal from '../Modal';
import { Calendar } from '../../svg';

interface DateFilterProps {
  onChange: (event: any) => void;
  values: any;
}

export const DateFilter: FC<DateFilterProps> = ({ onChange, values }) => {
  const options = [
    {
      value: '0',
      label: 'Oggi',
    },
    {
      value: '1',
      label: 'Ultima settimana',
    },
    {
      value: '2',
      label: 'Mese corrente',
    },
    {
      value: '3',
      label: 'Anno corrente',
    },
  ];
  const [currentValue, setCurrentValue] = useState<any>(options[0]);
  const [showDatepicker, setShowDatepicker] = useState(false);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const onChangeDatePicker = (dates: [start: Date, end: Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      end.setHours(23, 59, 59, 999);
      let time: any = { from: start.getTime(), to: end.getTime() };
      onChange(time);
      let value: any = {
        value: JSON.stringify(time),
        label: `${getDateAsString(start.getTime())} - ${getDateAsString(
          end.getTime()
        )}`,
      };
      setCurrentValue(value);
      setShowDatepicker(false);
      setStartDate(null);
      setEndDate(null);
    }
  };
  let fullYear = new Date();
  fullYear.setFullYear(fullYear.getFullYear() - 1);
  return (
    <>
      <GenericSelect
        id="Date"
        defaultValueIndex={0}
        onChange={(data: any) => {
          onChange(values[data.value]);
          setCurrentValue(data);
        }}
        options={options}
        showSelectedOption={true}
        showSelectionCircle={false}
        showSelectedOptionLabel={true}
        actions={[
          {
            label: 'Scegli date',
            Icon: <Calendar />,
            value: '1',
            onClick: () => setShowDatepicker(true),
          },
        ]}
        value={currentValue}
        label={''}
      />
      {showDatepicker && (
        <Modal
          title={'Scegli date'}
          showCloseButton={true}
          onClickCloseButton={() => setShowDatepicker(false)}
        >
          <DatePicker
            selected={startDate}
            onChange={onChangeDatePicker}
            startDate={startDate}
            endDate={endDate}
            minDate={fullYear}
            maxDate={new Date()}
            selectsRange
            inline
          />
        </Modal>
      )}
    </>
  );
};
