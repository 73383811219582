import { FC } from 'react';
import { Divider } from '../../svg';
import { SvgIcon } from '../SvgIcon';
import { AppSelector } from './AppSelector';
import { Logo } from './Logo';
import { LogoutIcon } from './LogoutIcon';

export const Header: FC = () => {
  return (
    <div className="h-12 w-full px-4 py-2 mt-1 inline-flex items-center justify-between">
      <div className="flex items-center w-full">
        <Logo />
        <div className="inline-flex items-center space-x-1 ml-4">
          <SvgIcon svg={<Divider />} className="mr-2" />
          <AppSelector />
        </div>
      </div>
      <div className="text-lg font-bold mx-auto w-full">
        Monitoraggio Sessioni
      </div>
      <div className="flex items-center space-x-1">
        <LogoutIcon />
      </div>
    </div>
  );
};
